.App {
  text-align: center;
  padding-top: 40px;
  font-family: 'Poppins', sans-serif;
}

header {
  max-width: 1000px;
  margin: auto auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .logo {
  height: 50px;
}

header .logo-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  font-size: 24px;
  color: #8b87de;
}

header a {
  text-decoration: none;
}

header .logo-container img {
  margin-right: 5px;
}

header .social-icons a {
margin: 0 8px 0 0;
}

header nav ul, footer nav ul {
  display: flex;
  padding:0;
  margin: 0;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
}

header nav ul li, footer nav ul li {
  display: inline-flex;
  height: 25px;
  margin:0 16px;
  font-size: 18px;
  padding: 0 auto;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
}

footer nav ul li {
  font-size: 14px;
}

footer div {
 display: flex;
 align-items: center;
}

footer div img {
  height: 40px;
 }

header nav ul .nav--active a, footer nav ul .nav--active a{
  /*border-bottom: 1px rgb(221, 221, 221) dashed;*/
  color: black;
  text-decoration: underline;
}

header nav ul li a, footer nav ul li a{
  text-decoration: none;
  color: rgb(43, 43, 43);
}

.header-hero {
  height:460px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 30px;
}

.hero-container-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
  text-align: left;
  width: 55%;
}

.hero-container-right {
  width: 45%;
  display: flex;
  align-items: center;
}

.hero-container-right img{
  width: 100%;
}

.header-hero h1 {
  text-align: left;
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 35px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  background: linear-gradient(235deg, #dd90f9, #e4b1f600 70.71%),
              linear-gradient(115deg, #508fdb, #508fdb00 70.71%),
              linear-gradient(355deg, #a251bd, #c87ee100 70.71%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.hero-subtitle {
  max-width: 500px;
  color: rgb(71, 71, 71);
}

.hero-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}

.hero-buttons a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.hero-buttons a button {
  margin-right: 10px;
}

.routes {
  min-height: 500px;
  /*display: flex;
  width: 100%;
  justify-items: center;
  justify-content: center;
  */
}

.routes > div {
  /*width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-items: center;*/
}

.entries, .page, .page_home {
  height: 100%;
  width: 100%;
  padding: 0;
}

.entries {
  display:flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  padding: 40px 0 0 0;
}

.entries-separator {
  height: 0px;
  width: 100%;
}

.entry {
  width: 100%;
  max-width: 900px;
  margin: 0px auto 70px;
  padding: 0;
  /* border-top: 1px dotted rgb(209, 209, 209);
  border-bottom: 1px dotted rgb(209, 209, 209); */
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: row;
}

.entry.entry_uneven {
  flex-direction: row;
}

.entry.entry_even {
  flex-direction: row-reverse;
}

/*
.entry:hover {
  border-top: 1px solid rgb(128, 128, 128);
  border-bottom: 1px solid rgb(128, 128, 128);
}
*/
.entry .entry_container {
  padding: 10px 40px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.entry .entry_image{
  width: 50%;
  display: flex;
  align-items: center;
}

.entry .entry_image img{
  width: 100%;
}

.entry_container.entry_uneven .entry__title,  .entry_container.entry_uneven .entry__description {
  width: 100%;
  text-align: left;
}

.entry_container.entry_even .entry__title,  .entry_container.entry_even .entry__description {
  width: 100%;
  text-align: right;
}

.entry .entry__title{
  text-transform: none;
  margin:0;
  color: rgb(0, 0, 0);  
  font-size: 26px;
  font-weight: bold;
}

.entry .entry__title .entry__icon {
  margin-right: 10px;
}

.entry .entry__description{
  color: rgb(130, 130, 130);
  font-size: 16px;
  margin: 8px 0 0 0;
}

.entry .entry__button{
  color: rgb(175, 175, 175);
  font-size: 16px;
  margin: 16px 0 0 0;
}

.page__title {

  font-weight: bold;
}

.page p {

}


footer {
  max-width: 900px;
  margin: 0 auto 40px;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-button {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  height: 60px;
  padding: 0 20px;
  font-size: 16px;
  background: #8b87de;
  color: rgb(255, 255, 255);
  border: 1px solid rgba(190, 190, 190, 0.729);
  background-size: 200% 200%;
  border-radius: 10px;
  cursor: pointer; 
}

.gradient-button:hover {
  background: linear-gradient(45deg, #508fdbdb,#c87ee1d9, #9749b888);
  background-size: 200% 200%;
  color: rgb(255, 255, 255);
  border: 1px solid rgba(67, 67, 67, 0.729);
  animation: gradientAnimation 2s ease infinite;
}

.gradient-button__light {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  color: #fff;
  border: 2px solid rgba(199, 199, 199, 0.729);
  background: none;
  border-radius: 20px;
  cursor: pointer; 
}

.gradient-button__light:not(.gradient-button__light__disabled):hover {
  background: linear-gradient(-90deg, #d6da0092,#03da7d92, #03854492);
  color: white;
  background-size: 200% 200%;
  border: 2px solid rgba(232, 232, 232, 0.878);
  animation: gradientAnimation 2s ease infinite;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.gradient-button__light__disabled {
  color: grey;
  border: 2px solid rgba(123, 123, 123, 0.878);
  cursor: auto;
}

.demo {
  max-width: 800px;
  margin: 40px auto;
}

.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@media (max-width: 1000px) {

  header, footer {
    padding: 0px 20px;
  }

  .header-hero {
    height: auto;
    flex-direction: column-reverse;
    justify-items: center;
    align-items: center;
    margin-top: 30px;
  }
  .hero-container-left{
    width: auto;
    padding: 0 20px;
    align-items: center;
  }
  #hero-title {
    text-align: center;
    margin: 20px 0;
  }
  
  .hero-subtitle {
    text-align: center;
  }
  .hero-container-right{
    width: auto;
    max-width: 450px;
    padding: 0 20px;
  }

  .header-hero h1 {
    margin: 0;
    padding: 0;
    font-size: 26px;
  }

  .hero-buttons {
    flex-direction: column;
  }

  .entry .entry_container {
    padding: 0;
    width: 100%;
    margin-top: 20px;
  }
  .entry_container.entry_uneven .entry__title,
  .entry_container.entry_uneven .entry__description {
    text-align: center;
  }
  .entry_container.entry_even .entry__title,
  .entry_container.entry_even .entry__description {
    text-align: center;
  }

  .entry {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
  .entry .entry_image {
    width: 100%;
    max-width: 450px;
  }

  .entry.entry_uneven {
    flex-direction: column;
  }
  .entry.entry_even {
    flex-direction: column;
  }

  .page {
    width: auto;
    padding: 0px 20px;
  }

}